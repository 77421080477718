<script lang="ts" setup>
import {type Measurement, fetch} from "../API";
import {computed, ref, watchEffect} from "vue";
import {onBeforeRouteLeave, onBeforeRouteUpdate, useRouter} from "vue-router";
import Button from "../components/Button.vue";
import ButtonContainer from "../components/ButtonContainer.vue";
import type {ComponentExposed} from "vue-component-type-helpers";
import type {Interval} from "luxon";
import Loader from "../components/Loader.vue";
import Measure from "../measurement/components/Measure.vue";
import Timeline from "../measurement/components/Timeline.vue";
import Validation from "../components/Validation.vue";
import {awaitable} from "../components/Awaitable";
import {createEmptyMeasurement} from "./components/Measurement";
import {toInterval} from "./components/Interval";
import {useAutosave} from "../components/Autosave";
import {useBreadcrumb} from "../Breadcrumb";
import {useSWR} from "../SWR";

interface Props
{
    date: string;
    indicatorId: string;
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: indicator} = useSWR("/project/{projectId}/indicator/{indicatorId}/", computed(() => ({indicatorId: props.indicatorId, projectId: props.projectId})), {}, null);
const {data: dates, mutate: mutateDates} = useSWR("/project/{projectId}/indicator/{indicatorId}/measurement/", computed(() => ({indicatorId: props.indicatorId, projectId: props.projectId})), {}, null);
const {data: existing, mutate: mutateExisting} = useSWR("/project/{projectId}/indicator/{indicatorId}/measurement/{date}/", computed(() => ({date: props.date, indicatorId: props.indicatorId, projectId: props.projectId})), {}, null);

const router = useRouter();
const getInterval = () =>
{
    if(indicator.value === null || indicator.value === undefined)
    {
        return null;
    }
    else
    {
        const {date} = props;
        const frequency = indicator.value.frequency!;
        return toInterval(date, frequency);
    }
};
const setInterval = async (value: Interval) => await router.replace(`/project/${props.projectId}/indicator/${props.indicatorId}/measurement/${value.start!.toISODate()}/`);
const interval = computed({get: getInterval, set: setInterval});

const measurement = ref<Measurement | null>();
watchEffect(() =>
{
    if(indicator.value === undefined || indicator.value === null || existing.value === undefined)
    {
        measurement.value = null;
    }
    else
    {
        if(existing.value === null)
        {
            measurement.value = createEmptyMeasurement(indicator.value.type!);
        }
        else
        {
            measurement.value = existing.value;
        }
    }
});

const title = computed(() =>
{
    if(indicator.value === undefined || indicator.value === null || interval.value === null)
    {
        return "";
    }
    else
    {
        switch(indicator.value.frequency!)
        {
            case "P0":
            {
                return "Once";
            }
            case "P1D":
            {
                return interval.value.start?.toFormat("yyyy-MM-dd");
            }
            default:
            {
                return interval.value.toFormat("yyyy-MM-dd", {separator: " to "});
            }
        }
    }
});

const validation = ref<ComponentExposed<typeof Validation>>();

const {autosave, save, saving} = useAutosave("put", "/project/{projectId}/indicator/{indicatorId}/measurement/", async (fetch) =>
{
    if(validation.value!.validate())
    {
        const {indicatorId, projectId} = props;
        const date = interval.value!.start!.toISODate()!;
        await fetch({indicatorId, projectId}, {}, {...measurement.value!, date});
        mutateDates([...dates.value ?? [], date]);
        mutateExisting({...measurement.value!, date});
    }
});

const submit = async () =>
{
    if(validation.value!.validate(true))
    {
        await save();
    }
};

const removable = computed(() =>
{
    if(dates.value === undefined || indicator.value === undefined || interval.value === null)
    {
        return false;
    }
    else
    {
        return dates.value.includes(interval.value.start!.toISODate()!);
    }
});
const removing = ref(false);
const remove = awaitable(removing, async () =>
{
    const {indicatorId, projectId} = props;
    const date = interval.value!.start!.toISODate()!;
    await fetch("delete", "/project/{projectId}/indicator/{indicatorId}/measurement/{date}/", {date, indicatorId, projectId}, {}, null);
    mutateDates(dates.value!.filter((d) => d !== date));
    mutateExisting(null);
    measurement.value = null;
});

onBeforeRouteLeave(async () =>
{
    if(validation.value!.validate())
    {
        await save();
    }
});
onBeforeRouteUpdate(async () =>
{
    if(validation.value!.validate())
    {
        await save();
    }
});

useBreadcrumb({indicatorId: () => indicator.value?.name});
</script>
<template>
    <Loader v-bind:loading="dates === undefined || indicator === undefined || indicator === null">
        <ButtonContainer>
            <template v-slot:default>
                <h1>{{indicator!.name}}</h1>
                <Timeline v-bind:existing="dates" v-bind:frequency="indicator!.frequency!" v-model:selected="interval"/>
                <div class="flex flex-gap-4 flex-items-top flex-justify-space-between">
                    <h2 class="flex-grow-1">{{title}}</h2>
                    <div class="flex flex-items-center">
                        <a class="link" role="button" v-bind:aria-disabled="removing || removable === false" v-on:click="remove">
                            <span class="inline-block i-svg-spinners:180-ring-with-bg h-4 m-r-1 w-4" v-show="removing"/>
                            <span class="inline-block">Clear input</span>
                        </a>
                    </div>
                </div>
                <Validation ref="validation">
                    <form class="flex flex-col flex-gap-5" v-on:submit.prevent="submit()">
                        <Measure v-bind:date="date" v-bind:indicator="indicator!" v-model:measurement="measurement!" v-on:update:measurement="autosave"/>
                    </form>
                </Validation>
            </template>
            <template v-slot:buttons>
                <Button class="flex-self-center" role="primary" type="button" v-bind:disabled="measurement === null" v-bind:loading="saving" v-on:click="submit">Save</Button>
            </template>
        </ButtonContainer>
    </Loader>
</template>