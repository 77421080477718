<script lang="ts">
export interface ToolbarButton
{
    action: () => Promise<void> | void;
    disabled?: boolean;
    icon: `i-${string}`;
    label: string;
    wait?: boolean;
}
</script>
<script lang="ts" setup>
import {awaitableSelection} from "./Awaitable";
import {ref} from "vue";

interface Props
{
    buttons?: ToolbarButton[];
}
const props = withDefaults(defineProps<Props>(), {buttons: () => []});

const executing = ref(new Set<number>());
const execute = awaitableSelection(executing, async (index: number) =>
{
    const {buttons} = props;
    const {action} = buttons[index];
    await action();
});
</script>
<template>
    <div class="auto-flow-col grid text-6" style="grid-auto-columns: min-content">
        <a class="aria-busy-color-green b-1 b-solid not-first-m-l-[-1px] first-b-rd-l-2 hover-z-1 last-b-rd-r-2 p-x-2 p-y-1 link" v-bind:aria-busy="executing.has(index) || wait" v-bind:aria-disabled="disabled || executing.has(index)" v-bind:aria-label="label" v-bind:key="index" v-on:click="execute(index)" v-for="({disabled, icon, label, wait}, index) of buttons">
            <span class="i-svg-spinners:180-ring-with-bg m-r-1 text-6" v-if="executing.has(index) || wait"/>
            <span class="m-r-1" v-bind:class="icon" v-else/>
            <span class="text-small white-space-nowrap">{{label}}</span>
        </a>
    </div>
</template>./Awaitable