<script lang="ts" setup>
import {computed, nextTick, ref} from "vue";
import Action from "../components/Action.vue";
import ButtonContainer from "../components/ButtonContainer.vue";
import Checkbox from "../components/Checkbox.vue";
import ClickToCopy from "../components/ClickToCopy.vue";
import ModalQuestion from "../components/ModalQuestion.vue";
import Table from "../components/Table.vue";
import Time from "../components/Time.vue";
import Toolbar from "../components/Toolbar.vue";
import {ToolbarButton} from "../components/Toolbar.vue";
import {awaitableSelection} from "../components/Awaitable";
import {fetch} from "../API";
import {invokable} from "../components/Invokable";
import {useBreadcrumb} from "../Breadcrumb";
import {useSWR} from "../SWR";

interface Props
{
    surveyId: string;
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: respondents, mutate} = useSWR("/project/{projectId}/survey/{surveyId}/respondent/", {projectId: props.projectId, surveyId: props.surveyId}, {}, null);

const selected = ref<string[]>([]);
const select = (checked: boolean, surveyId: string) =>
{
    if(checked)
    {
        selected.value.push(surveyId);
    }
    else
    {
        selected.value = selected.value.filter((id) => id !== surveyId);
    }
};

const addRespondent = async () =>
{
    const {projectId, surveyId} = props;
    const {length = 0} = respondents.value ?? {};
    const name = `Respondent ${length + 1}`;
    const result = await fetch("post", "/project/{projectId}/survey/{surveyId}/respondent/", {projectId, surveyId}, {}, {name});
    respondents.value!.push(result);
    await mutate();
};

const editing = ref(new Set<string>());
const editRespondent = async (respondentId: string) =>
{
    editing.value.add(respondentId);
    nextTick(() =>
    {
        const el = document.querySelector(`#id_${respondentId}`) as HTMLInputElement;
        el.select();
        el.focus();
    });
};

const saving = ref(new Set<string>());
const saveRespondent = awaitableSelection(saving, async (respondentId: string) =>
{
    const {projectId, surveyId} = props;
    const respondent = respondents.value!.find(({id}) => id === respondentId);
    if(respondent)
    {
        const {name} = respondent;
        editing.value.delete(respondentId);
        await fetch("patch", "/project/{projectId}/survey/{surveyId}/respondent/{respondentId}/", {projectId, respondentId, surveyId}, {}, {name});
        await mutate();
    }
});

const removing = ref(new Set<string>());
const remove = ref<(() => Promise<void>) | null>(null);
const removeRespondents = invokable(remove, awaitableSelection(removing, async (respondentIds: string[]) =>
{
    const {projectId, surveyId} = props;
    const promises = respondentIds.map((respondentId) => fetch("delete", "/project/{projectId}/survey/{surveyId}/respondent/{respondentId}/", {projectId, respondentId, surveyId}, {}, null));
    await Promise.all(promises);
    respondents.value = respondents.value!.filter(({id}) => !respondentIds.includes(id));
    selected.value = selected.value.filter((id) => !respondentIds.includes(id));
    await mutate();
}));

const updateName = (respondentId: string, name: string) => respondents.value = respondents.value!.map(({id, ...rest}) => id === respondentId ? {id, ...rest, name} : {id, ...rest});

const toolbar = computed(() =>
{
    const buttons: ToolbarButton[] =
    [
        {
            action: addRespondent,
            icon: "i-material-symbols:add",
            label: "Add respondent"
        },
        {
            action: () => removeRespondents([...selected.value]),
            disabled: selected.value.length === 0,
            icon: "i-material-symbols:delete-outline-rounded",
            label: "Remove respondents",
            wait: removing.value.size > 0
        }
    ];
    return buttons;
});

useBreadcrumb();
</script>
<template>
    <ButtonContainer>
        <template v-slot:default>
            <h1>Share survey</h1>
            <Toolbar class="m-t-6" v-bind:buttons="toolbar" />
            <form class="flex flex-col flex-gap-5">
                <Table class="m-t-3" collapse-last v-bind:columns="4" v-bind:rows="respondents">
                    <template v-slot:header:1>Name</template>
                    <template v-slot:header:2>Secret link</template>
                    <template v-slot:header:3>Last opened at</template>
                    <template v-slot:column:1="{row: {id, name}}">
                        <form class="w-100%" v-on:submit.prevent="saveRespondent(id)" v-if="editing.has(id)">
                            <input class="font-sans b-none b-b-1 b-b-solid b-b-gray block outline-none text-4 w-100%" v-bind:id="`id_${id}`" v-bind:value="name" v-on:input="(e) => updateName(id, (e.target as HTMLInputElement).value)"/>
                        </form>
                        <Checkbox v-bind:value="selected.includes(id)" v-on:update:value="(checked) => select(checked, id)" v-else>
                            <span class="font-bold">{{name}}</span>
                        </Checkbox>
                    </template>
                    <template v-slot:column:2="{row: {url}}">
                        <ClickToCopy v-bind:text="url">
                            <template v-slot:default="{copied}">
                                <a aria-label="Share" class="link">
                                    <span class="i-material-symbols:content-copy m-r-1" v-if="copied"/>
                                    <span class="i-material-symbols:content-copy-outline m-r-1" v-else/>
                                    <span>{{url}}</span>
                                </a>
                            </template>
                        </ClickToCopy>
                    </template>
                    <template v-slot:column:3="{row: {openedAt}}">
                        <Time v-bind:time="new Date(openedAt)" v-if="openedAt"/>
                        <span v-else>Never</span>
                    </template>
                    <template v-slot:column:4="{row: {id}}">
                        <div class="flex flex-gap-2 flex-justify-center flex-items-center flex-grow flex-row">
                            <a aria-busy="true" class="color-green i-svg-spinners:180-ring-with-bg text-6" v-if="saving.has(id) || removing.has(id)"/>
                            <template v-else>
                                <Action label="Save" icon="i-material-symbols:check" v-on:action="saveRespondent(id)" v-if="editing.has(id)"/>
                                <Action label="Edit" icon="i-material-symbols:edit-outline-rounded" v-on:action="editRespondent(id)" v-else/>
                            </template>
                        </div>
                    </template>
                    <template v-slot:placeholder v-if="respondents?.length === 0">
                        <div class="flex flex-justify-center">
                            <div class="color-lightgray">No respondents added.</div>
                            <Action icon="i-i-material-symbols:add" label="Add" v-on:action="addRespondent()"/>
                        </div>
                    </template>
                </Table>
            </form>
        </template>
    </ButtonContainer>
    <ModalQuestion v-bind:visible="remove !== null" v-on:answer:yes="remove!()" v-on:update:visible="remove = null">
        <template v-slot:title>Are you sure you want to delete {{selected.length}} {{selected.length === 1 ? "respondent" : "respondents"}}?</template>
        <template v-slot:text>Once deleted, you won’t be able to recover {{selected.length === 1 ? "it" : "them"}}.</template>
        <template v-slot:yes>Yes, delete {{selected.length === 1 ? "it" : "them"}}</template>
        <template v-slot:no>No, keep {{selected.length === 1 ? "it" : "them"}}</template>
    </ModalQuestion>
</template>