import {type Ref, inject, provide} from "vue";
import {onBeforeRouteLeave} from "vue-router";

export interface Navigation
{
    disabled?: boolean;
    href: string;
    icon?: `i-${string}:${string}`;
    scopes?: Array<Scope>;
    text?: string;
}

export type Scope = "authenticated" | "administrator" | "subscriber";

export function useNavigation()
{
    const navigation = inject<Ref<Navigation[]>>("navigation")!;
    onBeforeRouteLeave(() =>
    {
        navigation.value = [];
    });
    return navigation;
}

export function withNavigation(navigation: Ref<Navigation[]>)
{
    provide("navigation", navigation);
}